import React, { useState } from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'
import styled from 'styled-components'

const MailChimpInputGroup = styled.div`
  display: flex;
  padding: 6px 0;
  & > label {
    width: 100px;
  }
`
  
const MailChimpButton = styled.input`
  margin-left: 100px;
  color: #fff;
  background: #279896;
  border: none;
  margin-top: 6px;
  &:hover {
    cursor: pointer;
  }
`

const Feedback = styled.div`
  background: #eee;
  margin: 10px;
  margin-left: 100px;
  padding: 10px;
`

const MailChimp = () => {

  const [feedback, setFeedback] = useState('')
  const [unsavedChanges, setUnsavedChanges] = useState(false)
  const [loading, setLoading] = useState(false)

  const [email, setEmail] = useState('')
  const [firstname, setFirstname] = useState('')
  const [lastname, setLastname] = useState('')

  const handleUpdateEmail = (event) => {
    setUnsavedChanges(true)
    setEmail(event.currentTarget.value)
  }
  const handleUpdateFirstname = event => {
    setUnsavedChanges(true)
    setFirstname(event.target.value)
  }
  const handleUpdateLastname = event => {
    setUnsavedChanges(true)
    setLastname(event.target.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    addToMailchimp(email, {
      FNAME: firstname,
      LNAME: lastname
    })
    .then(({ msg, result }) => {
      setLoading(false)
      setUnsavedChanges(false)
      // console.log('msg', `${result}: ${msg}`)
      if (result !== 'success') {
        throw msg
      }
      // alert(msg)
      setFeedback(msg)
    })
    .catch((err) => {
      setLoading(false)
      setUnsavedChanges(false)
      console.log('err', err)
      // alert(err)
      setFeedback(err)
    })
  }

  return (
    <form onSubmit={handleSubmit}>
      <MailChimpInputGroup>
        <label htmlFor="email">Emailadres</label>
        <input
          placeholder="Emailaddress"
          name="email"
          id="email"
          type="text"
          onChange={handleUpdateEmail}
        />
      </MailChimpInputGroup>
      <MailChimpInputGroup>
        <label htmlFor="firstname">Voornaam</label>
        <input
          placeholder="Voornaam"
          name="firstname"
          id="firstname"
          type="text"
          onChange={handleUpdateFirstname}
        />
      </MailChimpInputGroup>
      <MailChimpInputGroup>
        <label htmlFor="lastname">Achternaam</label>
        <input
          placeholder="Achternaam"
          name="lastname"
          id="lastname"
          type="text"
          onChange={handleUpdateLastname}
        />
      </MailChimpInputGroup>
      <MailChimpButton type="submit" value={loading ? "..." : "Inschrijven"} disabled={!unsavedChanges} />
      {feedback !== '' ? (<Feedback>{feedback}</Feedback>) : null}
    </form>
  )
}

export default MailChimp