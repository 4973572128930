import React from 'react'
import { graphql } from "gatsby"
import Layout from '../components/layout'
import ReactMarkdown from 'react-markdown'
import styled from 'styled-components'
import SEO from "../components/seo"
import MailChimp from '../components/mail-chimp'
import { AiOutlineMail, AiOutlinePhone, AiOutlineFacebook, AiOutlineBank, AiOutlinePercentage } from "react-icons/ai"
import { FaRegAddressCard } from "react-icons/fa"

const StyleSheet = styled.div`
  margin-top: 20px;
  color: #666;
  & > p {
    line-height: 1.2;
    margin-bottom: 10px;
  }
    & > h6 {
    margin-top: 40px;
    margin-bottom: 10px;
  }
`

const Paragraph = styled.div`
  line-height: 1.2;
  margin-bottom: 10px;
`

const ContactWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 26px;
}
`

const ContactLink = styled.a`
  align-self: center;
  margin-left: 8px;
  text-decoration: none;
  color: #666;
`

const ContactField = styled.div`
  align-self: center;
  margin-left: 8px;
  color: #666;
`

const ContactPage = ({ data }) => {
  return (
    <div>
      <Layout active={'contact'}>
        <SEO title="Contact" />
        <StyleSheet>
          <h6>Contact</h6>
          <Paragraph>
            <ContactWrapper>
              <AiOutlineMail color="#279896" />
              <ContactLink href={`mailto:${data.file.childMarkdownRemark.frontmatter.email}`}>{data.file.childMarkdownRemark.frontmatter.email}</ContactLink>
            </ContactWrapper>
            <ContactWrapper>
              <AiOutlinePhone color="#279896" />
              <ContactLink href={`tel:${data.file.childMarkdownRemark.frontmatter.telephone}`}>{data.file.childMarkdownRemark.frontmatter.telephone}</ContactLink>
            </ContactWrapper>
            <ContactWrapper>
              <AiOutlineFacebook color="#279896" />
              <ContactLink href={data.file.childMarkdownRemark.frontmatter.facebook}>{data.file.childMarkdownRemark.frontmatter.facebook}</ContactLink>
            </ContactWrapper>
             <ContactWrapper>
              <FaRegAddressCard color="#279896" />
              <ContactField>{data.file.childMarkdownRemark.frontmatter.address}</ContactField>
            </ContactWrapper>
             <ContactWrapper>
              <AiOutlinePercentage color="#279896" />
              <ContactField>{data.file.childMarkdownRemark.frontmatter.vatnumber}</ContactField>
            </ContactWrapper>
             <ContactWrapper>
              <AiOutlineBank color="#279896" />
              <ContactField>{data.file.childMarkdownRemark.frontmatter.bankaccount}</ContactField>
            </ContactWrapper>
          </Paragraph>
          <br />

          <h6>Nieuwsbrief</h6>
          <p>In onze nieuwsbrief houden we u op de hoogte van onze activiteiten en interessante aanraders binnen de hedendaagse beeldende kunst.</p>
          <MailChimp />
          
          <h6>Wie is wie?</h6>
          <ReactMarkdown 
            source={data.file.childMarkdownRemark.rawMarkdownBody}
            parserOptions={{commonmark:true}}
          />
        </StyleSheet>
      </Layout>
    </div>
  )
}

export default ContactPage

export const contactPageQuery = graphql`
  query {
    file(name: {eq: "contact"}) {
      childMarkdownRemark {
        rawMarkdownBody
        frontmatter {
          title
          email
          telephone
          facebook
          address
          vatnumber
          bankaccount
        }
      }
    }
  }
`
